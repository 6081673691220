function Loading (props) {
    return(
        <div className="loading">
            <div className="ball" />
            <div className="ball" />
            <div className="ball" />
        </div>
    )
}

export default Loading